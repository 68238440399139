import { createRouter, createWebHistory } from 'vue-router'
//import { createRouter, createWebHashHistory } from 'vue-router'
//import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/howitswork',
    name: 'Howitswork',
    component: () => import('../views/Howitswork.vue')
  },
  {
    path: '/super-shopkeeper',
    name: 'SuperShopKeeper',
    component: () => import('../views/SuperShopKeeper.vue')
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: () => import('../views/Blogs.vue')
  },
  {
    path: '/shopconfig',
    name: 'shopconfig',
    component: () => import('../components/shopconfig')
  },
  {
    path: '/quick',
    name: 'Quick',
    component: () => import('../views/Quick.vue')
  },
  {
    path: '/singleblog',
    name: 'singleblog',
    component: () => import('../components/blogs/singleblog.vue')
  },
  {
    path: '/shopdetail',
    name: 'shopdetail',
    component: () => import('../components/shopdetail')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/Signup')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login')
  },
  {
    path: '/user_profile',
    name: 'user_profile',
    component: () => import('../components/Profile')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../components/userProfile')
  },
  {
    path: '/selectcountry',
    name: 'Select',
    component: () => import('../components/Select.vue')
  },
  {
    path: '/privacypolicy',
    name: 'pp',
    component: () => import('../components/privacy')
  },
  {
    path: '/shops/:bcode',
    name: 'Shops',
    component: () => import('../views/Shops.vue')
  },
  {
    path: '/countries',
    name: 'Countries',
    component: () => import('../views/Countries.vue')
  },
  {
    path: '/packages/:cty',
    name: 'Packages',
    component: () => import('../views/Packages.vue')
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('../views/Shop.vue')
  },
  {
    path: '/shop-reg',
    name: 'Shop-reg',
    component: () => import('../views/Shop-reg.vue')
  },
  {
    path: '/new-shop',
    name: 'new-Shop',
    component: () => import('../views/NewShop.vue')
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: () => import('../views/reset_password.vue')
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  }
})

export default router
