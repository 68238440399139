import axios from "axios";

class ServerInterface {
    constructor() {
        this._request_files = [];
        if(location.hostname == "23.22.213.121" || location.hostname == "tarospace.com") {
            this.url = 'https://api.tarospace.com/interface/index.php';
        } else {
            this.url = 'https://api.testspace.click/interface/index.php';
        }

        // this.url = 'http://api.tarospace.local/interface/index.php';
    }

    async getBusinessCategories() {
        let postBody = this.json('business_category.get_categories', {});
        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;
                            reject(error);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                            console.warn('llllll')
                        }

                    }
                    else {
                        reject("error")

                    }

                })
        });

    }

    async getUserShop(params) {
        let postBody = this.json('shops.getShopDetail', params);
        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;
                            reject(error);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                        }
                    }
                    else {
                        reject("error")
                    }

                })
        });
    }

    async listShops(params) {
        let postBody = this.json('shops.list_shops', params);
        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;
                            reject(error);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                        }
                    }
                    else {
                        reject("error")
                    }
                })
        });
    }

    async listPackages(params) {
        let postBody = this.json('shops.getpackages', params);
        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;
                            reject(error);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                        }
                    }
                    else {
                        reject("error")

                    }
                })
        });
    }

    async listCountries() {
        let postBody = this.json('country.listCountries', {})
        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;
                            reject(error);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);

                        }

                    }
                    else {
                        reject("error")
                    }
                })
        });
    }

    getUrl(method, params) {
        let url = this.url + "?json=" + this.json(method, params);
        return url;
    }

    // get the request files array
    getRequestFiles()
    {
        return this._request_files;
    }

    // add to our array of File() objects that will be sent with a request
    addRequestFile(variable_name, file_object)
    {
        // validate the variable name string against a regex check
        var regex = new RegExp(/^([A-Za-z0-9_]+)(\[\])?$/);
        if (!regex.test(variable_name))
        {
            throw new Error("1st argument to addRequestFile() is not a valid variable name !");
        }

        // file_object must be a file
        if (typeof file_object != 'object')
        {
            throw new Error("2st argument to addRequestFile() must be a File() object!");
        }

        this._request_files.push({"variable_name" : variable_name, "file" : file_object});
        return this;
    }

    // clear the request files array, so that no files will be sent with further jsonrpc requests
    clearRequestFiles()
    {
        this._request_files.length = 0;
        return this;
    }

    json(method, params) {
        // build the jsonrpc-syntax body
        let rpcBody = {
            jsonrpc:'2.0',
            method:method,
            params: params,
            id:this.makeid()
        };

        // if there are files supposed to go with this request, create a FormData object
        if (this.getRequestFiles().length > 0)
        {
            let wrapped_data = new FormData();
            wrapped_data.append('json', JSON.stringify(rpcBody));
            for (let i = 0; i < this.getRequestFiles().length; i++)
            {
                wrapped_data.append(this.getRequestFiles()[i].variable_name, this.getRequestFiles()[i].file);
            }
            rpcBody = wrapped_data;
        } else rpcBody = JSON.stringify(rpcBody);

        return rpcBody;
    }

    makeid() {
        let length = 8;
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    async logout(params = {}) {
        let postBody = this.json("users.logout", params);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;

                            reject(error.message);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                        }
                    }
                    else {
                        reject("error")
                    }
                })

        });

    }

    async getUserInfo(params = {}) {
        let postBody = this.json("users.loggedInUserInfo", params);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;

                            reject(error.message);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                        }
                    }
                    else {
                        reject("error")
                    }
                })

        });

    }

    async login(params = {}) {
        let postBody = this.json("users.login", params);
        // sessionStorage.setItem('password', params.password);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;

                            reject(error.message);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                        }
                    }
                    else {
                        reject("error")
                    }
                })

        });

    }

    async register(params) {
        params = {...params, ...{userType: 'shop_admin'}};
        let postBody = this.json("users.register_user", params);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;

                            reject(error.message);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                        }
                    }
                    else {
                        reject("error")
                    }
                })

        });

    }

    async signup(params) {
        params = {...params, ...{userType: 'shop_admin'}};
        let postBody = this.json("users.signup", params);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;

                            reject(error.message);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                        }
                    }
                    else {
                        reject("error")
                    }
                })

        });

    }

    async forgotPassword(params) {
        let postBody = this.json("users.forgotPassword", params);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;

                            reject(error.message);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                        }
                    }
                    else {
                        reject("error")
                    }
                })

        });

    }
    
    async reset_password(params) {
        let postBody = this.json("users.reset_password", params);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;

                            reject(error.message);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                        }
                    }
                    else {
                        reject("error")
                    }
                })

        });

    }

    async register_shop(params) {
        let postBody = this.json("shops.register_shop", params);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;

                            reject(error.message);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                        }
                    }
                    else {
                        reject("error")
                    }
                })

        });

    }

    async setDefaultLandingPage(params) {
        let postBody = this.json("shops.setDefaultLandingPage", params);

        return new Promise((resolve, reject) => {
            axios.post(this.url, postBody)
                .then((response) => {

                    if ('data' in response) {
                        let data = response.data;
                        if ('error' in data) {
                            let error = data.error;

                            reject(error.message);
                        }
                        if ('result' in data) {
                            let result = data.result;
                            resolve(result);
                        }
                    }
                    else {
                        reject("error")
                    }
                })

        });

    }
}

export default ServerInterface;